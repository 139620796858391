'use strict';

module.exports = {
  AUTH0_PRIMARY_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  ACG_DOMAIN: 'https://test-auto.acloud.guru',
  ACG_BERNIE_DOMAIN: 'https://staging-bernie.acloud.guru',
  ALGOLIA_STAGE: 'test',
  ALGOLIA_SEARCH_ONLY_API_KEY: '244df520f0ab19af76ed4ae40ce9a1ef',
  AUTH0_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  GRAPHQL_API_ENDPOINT: 'https://test-api.acloud.guru/bff/graphql',
  FORUM_API_ENDPOINT: 'https://test-api.acloud.guru/forum/forum',
  assetPrefix: 'https://test-internal-bernie.acloud.guru',
  CLOUDCRAFT_DOMAIN: 'https://staging-cloudcraft.acloud.guru',
  PS_LOGOUT_URL: 'http://app-stage.pluralsight.com/id/signout',
  ALLOWED_DOMAINS: [
    'http://127.0.0.1',
    'http://localhost',
    'https://*.acloud.guru',
    'https://brave-saha-7b070c.netlify.com',
    'https://*.auth0.com',
    'https://acg',
    'https://pocket-school.netlify.app',
    'https://*.linuxacademy.com',
    'https://*.pluralsight.com'
  ]
};
