'use strict';

const { COMMIT_HASH, BUILD_NUMBER } = process && process.env;

module.exports = {
  // available on server only
  ACG_DOMAIN: 'http://localhost:5000',
  ACG_BERNIE_DOMAIN: 'http://localhost:3000',
  AUTH0_PRIMARY_CLIENT_ID: 'JGSrENLfEp0LIAHTHja671Sde0pu0Q0Z',
  AUTH0_DOMAIN: 'acloudguru.auth0.com',
  ALGOLIA_APP_ID: '2AQ0ZGWJ3U',
  COMMIT_HASH,
  BUILD_NUMBER,
  GOOGLE_ANALYTICS_TRACKING_ID: false,
  CLOUDINARY_ACCOUNT: 'acloud-guru',
  CLOUDINARY_BASE_URL: 'https://res.cloudinary.com'
};
