import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import fetch from 'isomorphic-unfetch';
import { getConfig } from 'config';
import introspectionQueryResultData from './fragmentTypes.json';

import { authService } from '../../../src/services/auth';
import { isBrowser } from '../../../src/utils/typedGlobals';

let apolloClient;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

// Polyfill fetch() on the server (used by apollo-client)
if (isBrowser()) {
  // add our own property to the global object
  // TODO: update this when isomorphicunfetch updates it's types
  global.fetch = fetch;
}

const authLink = () =>
  setContext(() => {
    return authService.getToken().then((token) => {
      const { GRAPHQL_API_KEY } = getConfig();
      const jwtToken = token || ''; // we dont want to interpolate null or undefined

      const headers = {
        authorization: `Bearer ${jwtToken}`
      };

      if (GRAPHQL_API_KEY) {
        headers['x-api-key'] = GRAPHQL_API_KEY;
      }

      return { headers };
    });
  });

const httpLink = () => {
  const { GRAPHQL_API_ENDPOINT } = getConfig();

  return new HttpLink({
    credentials: 'same-origin',
    uri: GRAPHQL_API_ENDPOINT
  });
};

const create = (initialState) => {
  const state = { ...initialState };

  return new ApolloClient({
    connectToDevTools: isBrowser(),
    ssrMode: !isBrowser(), // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([authLink(), httpLink()]),
    cache: new InMemoryCache({ fragmentMatcher }).restore(state)
  });
};

const initApollo = (initialState, options) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isBrowser()) {
    return create(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
};

export { initApollo };
