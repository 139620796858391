import { AlgoliaProvider, defineAlgoliaApp } from 'react-algolia';
import { getConfig } from 'config';

const { ALGOLIA_APP_ID, ALGOLIA_STAGE, ALGOLIA_SEARCH_ONLY_API_KEY } = getConfig();

const Algolia = ({ children }) => {
  const courses = defineAlgoliaApp(
    ALGOLIA_APP_ID,
    ALGOLIA_SEARCH_ONLY_API_KEY,
    `${ALGOLIA_STAGE}_courses`
  );

  return (
    <AlgoliaProvider applications={[courses]}>
      {children}
    </AlgoliaProvider>
  );
};

export { Algolia };