const merge = require('lodash.merge');

const getConfigForEnvironment = environment => {
  return merge(require('./common.config.js'), require(`./${environment}.config.js`));
};

const { 
  ACG_DOMAIN,
  AUTH0_CUSTOM_DOMAIN, 
  AUTH0_API_AUDIENCE, 
  ACG_BERNIE_DOMAIN, 
  ALGOLIA_STAGE, 
  ALGOLIA_APP_ID, 
  ALGOLIA_SEARCH_ONLY_API_KEY, 
  AUTH0_CLIENT_ID, 
  GRAPHQL_API_ENDPOINT,
  FORUM_API_ENDPOINT,
  ALLOWED_DOMAINS
} = process && process.env;

const buildtimeConfig = {
  ACG_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  ACG_BERNIE_DOMAIN,
  ALGOLIA_STAGE,
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_ONLY_API_KEY,
  AUTH0_CLIENT_ID,
  GRAPHQL_API_ENDPOINT,
  FORUM_API_ENDPOINT,
  assetPrefix: 'https://production-internal-bernie.acloud.guru',
  ALLOWED_DOMAINS
};

const makeConfig = () => {
  const environment =
  process && process.env && process.env.ACG_ENV !== 'production' && process.env.ACG_ENV !== 'staging'
    ? 'dev'
    : process.env.ACG_ENV;

  if (!environment) {
    throw new Error(
      'Please set the ACG_ENV environment variable. now -e ACG_ENV=production or ACG_ENV=production npm run build'
    );
  }

  let config = {
    ...buildtimeConfig,
    ...getConfigForEnvironment(environment)
  };

  return config;
};

module.exports = {
  getConfig: () => makeConfig(),
  getConfigForEnvironment
};
