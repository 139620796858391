import 'regenerator-runtime/runtime';
import App from 'next/app';
import React from 'react';
import Head from 'next/head';
import { ApolloProvider } from 'react-apollo';
import { Algolia } from 'lib/algolia';

import { withApolloClient } from '../src/lib/apollo/withApolloClient';

import '../src/styles/uikit.css';

class AdminApp extends App {
  render() {
    const { apolloClient, Component, pageProps, router } = this.props;
    return (
      <>
        <Head>
          <title>Bernie | A Cloud Guru</title>
        </Head>

        <ApolloProvider client={apolloClient}>
          <Algolia>
            <Component {...pageProps} router={router} />
          </Algolia>
        </ApolloProvider>
      </>
    )
  }
}

export default withApolloClient(AdminApp);
