'use strict';

module.exports = {
  AUTH0_PRIMARY_CLIENT_ID: 'Bt4dXfW1xk6Wur9rNBOOZTidZakD092X',
  AUTH0_DOMAIN: 'acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://acloudguru.auth0.com/api/v2/',
  ACG_DOMAIN: 'https://acloud.guru',
  ACG_BERNIE_DOMAIN: 'https://bernie.acloud.guru',
  ALGOLIA_STAGE: 'prod',
  ALGOLIA_SEARCH_ONLY_API_KEY: 'bd07683ba0f90f58b2ea5def1ec0cc27',
  AUTH0_CLIENT_ID: 'Bt4dXfW1xk6Wur9rNBOOZTidZakD092X',
  GRAPHQL_API_ENDPOINT: 'https://prod-api.acloud.guru/bff/graphql',
  FORUM_API_ENDPOINT: 'https://prod-api.acloud.guru/forum/forum',
  assetPrefix: 'https://production-internal-bernie.acloud.guru',
  CLOUDCRAFT_DOMAIN: 'https://cloudcraft.acloud.guru',
  PS_LOGOUT_URL: 'http://app.pluralsight.com/id/signout',
  ALLOWED_DOMAINS: [
    'https://acloud.guru',
    'https://*.acloud.guru',
    'https://acloudguru.firebaseapp.com',
    'https://mr-magoriums-wonder-emporium.netlify.app',
    'https://*.linuxacademy.com',
    'https://acg',
    'https://*.auth0.com',
    'https://*.pluralsight.com'
  ]
};
